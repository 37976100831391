import React from "react";
import { RouteObject } from "react-router-dom";
import List from "../../Pages/Setting/Document/List";
import AddUpdate from "../../Pages/Setting/Document/AddUpdate";
import DocumentView from "../../Pages/Setting/Document/DocumentView";
import AuthorizedRoute from "../../Util/AuthorizedRoute";
import {
  ADD_DOCUMENT_TEMPLATES,
  DELETE_DOCUMENT_TEMPLATES,
  RESTORE_DOCUMENT_TEMPLATES,
  UPDATE_DOCUMENT_TEMPLATES,
  VIEW_DOCUMENT_TEMPLATES,
} from "../../Util/PermissionList";

export const DOCUMENT_TITLE = "Document";
export const DOCUMENT_LIST = "/document";
export const DOCUMENT_ADD = "/document/add";
export const DOCUMENT_EDIT = "/document/:id/edit";
export const DOCUMENT_VIEW = "/document/:id/show";
export const DOCUMENT_ARCHIVE = "/document/archive";

const Document: RouteObject[] = [
  {
    path: DOCUMENT_LIST,
    element: (
      <AuthorizedRoute
        permission={VIEW_DOCUMENT_TEMPLATES}
        checkPermisson={{
          addPermission: ADD_DOCUMENT_TEMPLATES,
          updatePermission: UPDATE_DOCUMENT_TEMPLATES,
          deletePermission: DELETE_DOCUMENT_TEMPLATES,
          restorePermission: RESTORE_DOCUMENT_TEMPLATES,
          viewPermission: VIEW_DOCUMENT_TEMPLATES,
        }}
        element={<List />}
      />
    ),
  },
  {
    path: DOCUMENT_ADD,
    element: (
      <AuthorizedRoute
        element={<AddUpdate />}
        permission={ADD_DOCUMENT_TEMPLATES}
        checkPermisson={{
          addPermission: ADD_DOCUMENT_TEMPLATES,
          updatePermission: UPDATE_DOCUMENT_TEMPLATES,
          deletePermission: DELETE_DOCUMENT_TEMPLATES,
          restorePermission: RESTORE_DOCUMENT_TEMPLATES,
          viewPermission: VIEW_DOCUMENT_TEMPLATES,
        }}
      />
    ),
  },
  {
    path: DOCUMENT_EDIT,
    element: (
      <AuthorizedRoute
        element={<AddUpdate />}
        permission={UPDATE_DOCUMENT_TEMPLATES}
        checkPermisson={{
          addPermission: ADD_DOCUMENT_TEMPLATES,
          updatePermission: UPDATE_DOCUMENT_TEMPLATES,
          deletePermission: DELETE_DOCUMENT_TEMPLATES,
          restorePermission: RESTORE_DOCUMENT_TEMPLATES,
          viewPermission: VIEW_DOCUMENT_TEMPLATES,
        }}
      />
    ),
  },
  {
    path: DOCUMENT_VIEW,
    element: (
      <AuthorizedRoute
        element={<DocumentView />}
        checkPermisson={{
          addPermission: ADD_DOCUMENT_TEMPLATES,
          updatePermission: UPDATE_DOCUMENT_TEMPLATES,
          deletePermission: DELETE_DOCUMENT_TEMPLATES,
          restorePermission: RESTORE_DOCUMENT_TEMPLATES,
          viewPermission: VIEW_DOCUMENT_TEMPLATES,
        }}
        permission={VIEW_DOCUMENT_TEMPLATES}
      />
    ),
  },
  {
    path: DOCUMENT_ARCHIVE,
    element: (
      <AuthorizedRoute
        element={<List />}
        checkPermisson={{
          addPermission: ADD_DOCUMENT_TEMPLATES,
          updatePermission: UPDATE_DOCUMENT_TEMPLATES,
          deletePermission: DELETE_DOCUMENT_TEMPLATES,
          restorePermission: RESTORE_DOCUMENT_TEMPLATES,
          viewPermission: VIEW_DOCUMENT_TEMPLATES,
        }}
        permission={DELETE_DOCUMENT_TEMPLATES}
      />
    ),
  },
];

export default Document;
