import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { setToast } from "../../../Util/Toast";
import { useApi } from "../../../Controller/ApiController";
import { Container } from "react-bootstrap";
import CustomSkeleton from "../../../Components/CustomSkeleton";

interface DocumentViewProps {}

const DocumentView: React.FC<DocumentViewProps> = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [html, setHtml] = useState<string>("");
  const [css, setCss] = useState<string>("");

  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        try {
          const itemData: any = await getFormData(id);
          const jsonData = JSON.parse(itemData.content);
          let { html, css } = jsonData;
          setHtml(html);
          setCss(css);
        } catch (error: any) {
          setToast("error", error.response?.data.message);
        } finally {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const { apiGet } = useApi();
  const getFormData = async (itemId: string) => {
    const item = await apiGet(`document-templates/` + itemId?.toString());
    return item.data;
  };

  if (loading) {
    return <CustomSkeleton height={500} />;
  }

  return (
    <Container>
      <style>{css}</style>
      <div dangerouslySetInnerHTML={{ __html: html }} />
    </Container>
  );
};

export default DocumentView;
