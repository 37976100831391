import { RouteObject } from "react-router-dom";
import List from "../../Pages/BuyersAndSuppliers/List";
import AddUpdate from "../../Pages/BuyersAndSuppliers/AddUpdate";
import {
  ADD_COUNTER_PARTY_ADDRESS,
  ADD_COUNTER_PARTY_COMPANY,
  ADD_COUNTER_PARTY_PERSON,
  ADD_COUNTER_PARTY_TAG,
  DELETE_COUNTER_PARTY_ADDRESS,
  DELETE_COUNTER_PARTY_COMPANY,
  DELETE_COUNTER_PARTY_PERSON,
  DELETE_COUNTER_PARTY_TAG,
  RESTORE_COUNTER_PARTY_ADDRESS,
  RESTORE_COUNTER_PARTY_COMPANY,
  RESTORE_COUNTER_PARTY_PERSON,
  RESTORE_COUNTER_PARTY_TAG,
  UPDATE_COUNTER_PARTY_ADDRESS,
  UPDATE_COUNTER_PARTY_COMPANY,
  UPDATE_COUNTER_PARTY_PERSON,
  UPDATE_COUNTER_PARTY_TAG,
  VIEW_COUNTER_PARTY_ADDRESS,
  VIEW_COUNTER_PARTY_COMPANY,
  VIEW_COUNTER_PARTY_PERSON,
  VIEW_COUNTER_PARTY_TAG,
} from "../../Util/PermissionList";
import AuthorizedRoute from "../../Util/AuthorizedRoute";
import BalanceSheet from "../../Pages/BuyersAndSuppliers/Components/BalanceSheet";

export const BUYERS_AND_SUPPLIERS_TITLE = "Buyers & Suppliers";
export const BUYERS_AND_SUPPLIERS_LIST = "/BuyersAndSuppliers";
export const BUYERS_AND_SUPPLIERS_ADD = "/BuyersAndSuppliers/add";
export const BUYERS_AND_SUPPLIERS_EDIT = "/BuyersAndSuppliers/:id/edit";
export const BUYERS_AND_SUPPLIERS_BALANCE_SHEET =
  "/BuyersAndSuppliers/:id/balance-sheet";
export const BUYERS_AND_SUPPLIERS_ARCHIVE = "/BuyersAndSuppliers/archive";

const BuyersAndSuppliers: RouteObject[] = [
  {
    path: BUYERS_AND_SUPPLIERS_LIST,
    element: (
      <AuthorizedRoute
        permission={VIEW_COUNTER_PARTY_COMPANY}
        checkPermisson={{
          viewPermission: VIEW_COUNTER_PARTY_COMPANY,
          addPermission: ADD_COUNTER_PARTY_COMPANY,
          updatePermission: UPDATE_COUNTER_PARTY_COMPANY,
          deletePermission: DELETE_COUNTER_PARTY_COMPANY,
          restorePermission: RESTORE_COUNTER_PARTY_COMPANY,
        }}
        element={<List />}
      />
    ),
  },
  {
    path: BUYERS_AND_SUPPLIERS_ADD,
    element: (
      <AuthorizedRoute
        element={<AddUpdate />}
        checkPermisson={{
          viewPermission: VIEW_COUNTER_PARTY_COMPANY,
          addPermission: ADD_COUNTER_PARTY_COMPANY,
          updatePermission: UPDATE_COUNTER_PARTY_COMPANY,
          deletePermission: DELETE_COUNTER_PARTY_COMPANY,
          restorePermission: RESTORE_COUNTER_PARTY_COMPANY,
        }}
        permission={ADD_COUNTER_PARTY_COMPANY}
      />
    ),
  },
  {
    path: BUYERS_AND_SUPPLIERS_EDIT,
    element: (
      <AuthorizedRoute
        element={<AddUpdate />}
        exception={true}
        checkPermisson={{
          updatePermission: UPDATE_COUNTER_PARTY_COMPANY,
          VIEW_COUNTER_PARTY_PERSON,
          ADD_COUNTER_PARTY_PERSON,
          UPDATE_COUNTER_PARTY_PERSON,
          DELETE_COUNTER_PARTY_PERSON,
          RESTORE_COUNTER_PARTY_PERSON,
          VIEW_COUNTER_PARTY_TAG,
          ADD_COUNTER_PARTY_TAG,
          UPDATE_COUNTER_PARTY_TAG,
          DELETE_COUNTER_PARTY_TAG,
          RESTORE_COUNTER_PARTY_TAG,
          VIEW_COUNTER_PARTY_ADDRESS,
          ADD_COUNTER_PARTY_ADDRESS,
          UPDATE_COUNTER_PARTY_ADDRESS,
          DELETE_COUNTER_PARTY_ADDRESS,
          RESTORE_COUNTER_PARTY_ADDRESS,
        }}
        permission={UPDATE_COUNTER_PARTY_COMPANY}
      />
    ),
  },
  {
    path: BUYERS_AND_SUPPLIERS_ARCHIVE,
    element: (
      <AuthorizedRoute
        element={<List />}
        checkPermisson={{
          viewPermission: VIEW_COUNTER_PARTY_COMPANY,
          addPermission: ADD_COUNTER_PARTY_COMPANY,
          updatePermission: UPDATE_COUNTER_PARTY_COMPANY,
          deletePermission: DELETE_COUNTER_PARTY_COMPANY,
          restorePermission: RESTORE_COUNTER_PARTY_COMPANY,
        }}
        permission={DELETE_COUNTER_PARTY_COMPANY}
      />
    ),
  },
  {
    path: BUYERS_AND_SUPPLIERS_BALANCE_SHEET,
    element: <AuthorizedRoute element={<BalanceSheet />} />,
  },
];

export default BuyersAndSuppliers;
