import { Model } from "@tailflow/laravel-orion/lib/model";

export class Approvals extends Model<{
  invoice: string;
  grn_qir_sub_contact: string;
  inward_purchase_order: string;
  challan_order_confirmation: string;
  challan_so_sc: string;
  b2c_invoice: string;
  manual_adjustment: string;
  physical_stock_reconciliation: string;
  grn_qir_purchase_order: string;
  grn_qir_so_sc: string;
  inward_so_sc: string;
  challan_sub_contract: string;
  purchase_return_challan: string;
  stock_transfer: string;
  process: string;
  created_at: string;
  updated_at: string;
}> {
  public $resource(): string {
    return "approvals";
  }
}
