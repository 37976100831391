import "bootstrap/dist/css/bootstrap.min.css";
import { APPROVALS_TITLE } from "../../../Routes/Setting/Approvals";
import { Approvals } from "../../../Model/Setting/Approvals";
import AddUpdateForm from "../../../Components/AddUpdateForm";
import { setToast } from "../../../Util/Toast";
import { useApi } from "../../../Controller/ApiController";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import FieldDescription from "../../../Components/FieldDescription";
import Notes from "../../../Components/Notes";
import ActivityLogs from "../../../Components/ActivityLogs";
import CustomSkeleton from "../../../Components/CustomSkeleton";

const AddUpdate = () => {
  let title = APPROVALS_TITLE;
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState<any>({});
  const { apiGet, apiPut } = useApi();
  const [formError, setFormError] = useState<string | null>(null);
  const [isShows, setIsShows] = useState<boolean>(false);
  const [id, setId] = useState("");
  const [activeTab, setActiveTab] = useState("notes");
  const [customSubmitting, setCustomSubmitting] = useState(false); // State variable to track form submission status

  const navigate = useNavigate();

  const formFields = [
    {
      id: "inputinvoice",
      label: "Invoice",
      type: "switch",
      col: 6,
      name: "invoice",
    },
    {
      id: "inputgrn_qir_sub_contact",
      label: "grn_qir_sub_contact",
      type: "switch",
      col: 6,
      name: "grn_qir_sub_contact",
    },
    {
      id: "inputinward_purchase_order",
      label: "inward_purchase_order",
      type: "switch",
      col: 6,
      name: "inward_purchase_order",
    },
    {
      id: "inputchallan_order_confirmation",
      label: "challan_order_confirmation",
      type: "switch",
      col: 6,
      name: "challan_order_confirmation",
    },
    {
      id: "inputchallan_so_sc",
      label: "challan_so_sc",
      type: "switch",
      col: 6,
      name: "challan_so_sc",
    },
    {
      id: "inputb2c_invoice",
      label: "b2c_invoice",
      type: "switch",
      col: 6,
      name: "b2c_invoice",
    },
    {
      id: "inputmanual_adjustment",
      label: "manual_adjustment",
      type: "switch",
      col: 6,
      name: "manual_adjustment",
    },
    {
      id: "inputphysical_stock_reconciliation",
      label: "physical_stock_reconciliation",
      type: "switch",
      col: 6,
      name: "physical_stock_reconciliation",
    },
    {
      id: "inputgrn_qir_purchase_order",
      label: "grn_qir_purchase_order",
      type: "switch",
      col: 6,
      name: "grn_qir_purchase_order",
    },
    {
      id: "inputinward_so_sc",
      label: "inward_so_sc",
      type: "switch",
      col: 6,
      name: "inward_so_sc",
    },
    {
      id: "inputchallan_sub_contract",
      label: "challan_sub_contract",
      type: "switch",
      col: 6,
      name: "challan_sub_contract",
    },
    {
      id: "inputpurchase_return_challan",
      label: "purchase_return_challan",
      type: "switch",
      col: 6,
      name: "purchase_return_challan",
    },
    {
      id: "inputstock_transfer",
      label: "stock_transfer",
      type: "switch",
      col: 6,
      name: "stock_transfer",
    },
    {
      id: "inputprocess",
      label: "process",
      type: "switch",
      col: 6,
      name: "process",
    },
  ];

  const handleCustomSubmit = async (formData: any) => {
    setCustomSubmitting(true);
    const {
      grn_qir_sub_contact,
      inward_purchase_order,
      challan_order_confirmation,
      challan_so_sc,
      b2c_invoice,
      manual_adjustment,
      physical_stock_reconciliation,
      grn_qir_purchase_order,
      invoice,
      inward_so_sc,
      challan_sub_contract,
      purchase_return_challan,
      stock_transfer,
      process,
    } = formData;

    const inputData = {
      grn_qir_sub_contact:
        grn_qir_sub_contact === undefined
          ? "manual"
          : grn_qir_sub_contact === true
          ? "auto"
          : "manual",
      inward_purchase_order:
        inward_purchase_order === undefined
          ? "manual"
          : inward_purchase_order === true
          ? "auto"
          : "manual",
      challan_order_confirmation:
        challan_order_confirmation === undefined
          ? "manual"
          : challan_order_confirmation === true
          ? "auto"
          : "manual",
      challan_so_sc:
        challan_so_sc === undefined
          ? "manual"
          : challan_so_sc === true
          ? "auto"
          : "manual",
      b2c_invoice:
        b2c_invoice === undefined
          ? "manual"
          : b2c_invoice === true
          ? "auto"
          : "manual",
      manual_adjustment:
        manual_adjustment === undefined
          ? "manual"
          : manual_adjustment === true
          ? "auto"
          : "manual",
      physical_stock_reconciliation:
        physical_stock_reconciliation === undefined
          ? "manual"
          : physical_stock_reconciliation === true
          ? "auto"
          : "manual",
      grn_qir_purchase_order:
        grn_qir_purchase_order === undefined
          ? "manual"
          : grn_qir_purchase_order === true
          ? "auto"
          : "manual",
      invoice:
        invoice === undefined ? "manual" : invoice === true ? "auto" : "manual",
      inward_so_sc:
        inward_so_sc === undefined
          ? "manual"
          : inward_so_sc === true
          ? "auto"
          : "manual",
      challan_sub_contract:
        challan_sub_contract === undefined
          ? "manual"
          : challan_sub_contract === true
          ? "auto"
          : "manual",
      purchase_return_challan:
        purchase_return_challan === undefined
          ? "manual"
          : purchase_return_challan === true
          ? "auto"
          : "manual",
      stock_transfer:
        stock_transfer === undefined
          ? "manual"
          : stock_transfer === true
          ? "auto"
          : "manual",
      process:
        process === undefined ? "manual" : process === true ? "auto" : "manual",
    };
    try {
      await apiPut(`approvals-updates`, inputData);
      navigate(`/${APPROVALS_TITLE.toLowerCase().replace(" ", "-") + "s"}`);
      setToast("success", `Approvals Successfully Submitted`);
    } catch (error: any) {
      if (error.response?.data?.errors) {
        setFormError(error.response.data.errors);
      } else if (error.response?.data?.message) {
        setFormError(error.response.data.message);
      } else {
        setToast("error", "An error occurred while processing your request");
      }
    } finally {
      setCustomSubmitting(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        let URL = new Approvals().$resource();
        const itemData = await apiGet(`${URL}`);
        let data = itemData.data;
        setId(data.id);
        data["b2c_invoice"] = data["b2c_invoice"] === "auto";
        data["challan_order_confirmation"] =
          data["challan_order_confirmation"] === "auto";
        data["challan_so_sc"] = data["challan_so_sc"] === "auto";
        data["challan_sub_contract"] = data["challan_sub_contract"] === "auto";
        data["grn_qir_purchase_order"] =
          data["grn_qir_purchase_order"] === "auto";
        data["grn_qir_so_sc"] = data["grn_qir_so_sc"] === "auto";
        data["grn_qir_sub_contact"] = data["grn_qir_sub_contact"] === "auto";
        data["invoice"] = data["invoice"] === "auto";
        data["inward_purchase_order"] =
          data["inward_purchase_order"] === "auto";
        data["inward_so_sc"] = data["inward_so_sc"] === "auto";
        data["manual_adjustment"] = data["manual_adjustment"] === "auto";
        data["physical_stock_reconciliation"] =
          data["physical_stock_reconciliation"] === "auto";
        data["process"] = data["process"] === "auto";
        data["purchase_return_challan"] =
          data["purchase_return_challan"] === "auto";
        data["stock_transfer"] = data["stock_transfer"] === "auto";

        setFormData(data);
        setLoading(false);
      } catch (error: any) {
        setToast("error", error.response.data.message);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleHelpButtonClick = () => {
    setIsShows(!isShows);
    const formBottom = document.getElementById("form-bottom");
    if (formBottom) {
      formBottom.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };
  const formFieldsForFieldDescription: any = [
    {
      label: "Approval",
      description:
        "Use the toggle button to swiftly approve or disapprove request.<br/> -ON:Indicates approval is granted. <br/>-OFF:Indicates approval is not granted.",
    },
  ];

  function CustomFormSkeleton() {
    const skeletonStyle = { marginBottom: "10px" };

    return (
      <div
        className="pt-2 pb-2"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <div style={{ width: "48%" }}>
          {[...Array(4)].map((_, index) => (
            <div key={index} style={{ marginBottom: "40px" }}>
              <CustomSkeleton
                count={1}
                height={20}
                customOuterStyle={{ ...skeletonStyle, width: "70%" }}
              />
            </div>
          ))}
        </div>
        <div style={{ width: "48%" }}>
          {[...Array(4)].map((_, index) => (
            <div key={index} style={{ marginBottom: "40px" }}>
              <CustomSkeleton
                count={1}
                height={20}
                customOuterStyle={{ ...skeletonStyle, width: "70%" }}
              />
            </div>
          ))}
        </div>
      </div>
    );
  }

  return (
    <>
      <div>
        <span style={{ float: "inline-end" }}>
          <Button variant="outline-secondary" onClick={handleHelpButtonClick}>
            Need Help?
          </Button>
        </span>
        <AddUpdateForm
          formFields={formFields}
          model={Approvals}
          title={title}
          customTitle={title}
          customFormSubmit={handleCustomSubmit}
          dynamicFormData={formData}
          dynamicFormError={formError}
          customSubmitting={customSubmitting}
          loadingStatus={loading}
          CustomFormSkeleton={CustomFormSkeleton}
        />
      </div>

      {id && (
        <div className="card mt-4" style={{ marginBottom: "30px" }}>
          <div className="card-header d-flex">
            <h5
              style={{ cursor: "pointer", fontSize: "17px" }}
              className={`mb-0 me-3 ${
                activeTab === "notes" ? "text-primary" : ""
              }`}
              onClick={() => setActiveTab("notes")}
            >
              Notes
            </h5>
            <h5
              style={{ cursor: "pointer", fontSize: "17px" }}
              className={`mb-0 me-3 ${
                activeTab === "activityLogs" ? "text-primary" : ""
              }`}
              onClick={() => setActiveTab("activityLogs")}
            >
              Activity Logs
            </h5>
          </div>

          <div
            className="card-body"
            style={{
              height: "auto",
              overflow: "auto",
              backgroundColor: "#e4e4e4",
            }}
          >
            {activeTab === "notes" && <Notes id={id} model={Approvals} />}
            {activeTab === "activityLogs" && (
              <ActivityLogs auditable_type={"APPROVALS"} id={id} />
            )}
          </div>
        </div>
      )}
      <div id="form-bottom">
        <FieldDescription
          title={title}
          formFields={formFieldsForFieldDescription}
          isShows={isShows}
        />
      </div>
    </>
  );
};

export default AddUpdate;
