import { RouteObject } from "react-router-dom";
import List from "../../Pages/Inventory/Product/List";
import AddUpdate from "../../Pages/Inventory/Product/AddUpdate";
import AuthorizedRoute from "../../Util/AuthorizedRoute";
import {
  ADD_PRODUCT,
  DELETE_PRODUCT,
  RESTORE_PRODUCT,
  UPDATE_PRODUCT,
  VIEW_PRODUCT,
  VIEW_STOCK_INVENTORY,
} from "../../Util/PermissionList";
import ProductInventoryHistoryList from "../../Pages/Inventory/Product/ProductInventoryHistoryList";

export const PRODUCT_TITLE = "Product";
export const PRODUCT_LIST = "/products";
export const PRODUCT_ADD = "/products/add";
export const PRODUCT_EDIT = "/products/:id/edit";
export const PRODUCT_INVENTORY_HISTORY = "/products/:id/inventory-history";
export const PRODUCT_ARCHIVE = "/products/archive";

const ProductRoutes: RouteObject[] = [
  {
    path: PRODUCT_LIST,
    element: (
      <AuthorizedRoute
        permission={VIEW_PRODUCT}
        exception={true}
        checkPermisson={{
          addPermission: ADD_PRODUCT,
          updatePermission: UPDATE_PRODUCT,
          deletePermission: DELETE_PRODUCT,
          restorePermission: RESTORE_PRODUCT,
          viewPermission: VIEW_PRODUCT,
          VIEW_STOCK_INVENTORY,
        }}
        element={<List />}
      />
    ),
  },
  {
    path: PRODUCT_ADD,
    element: (
      <AuthorizedRoute
        element={<AddUpdate title={PRODUCT_TITLE} listRoute={PRODUCT_LIST} />}
        permission={ADD_PRODUCT}
      />
    ),
  },
  {
    path: PRODUCT_EDIT,
    element: (
      <AuthorizedRoute
        element={<AddUpdate title={PRODUCT_TITLE} listRoute={PRODUCT_LIST} />}
        permission={UPDATE_PRODUCT}
      />
    ),
  },
  {
    path: PRODUCT_ARCHIVE,
    element: (
      <AuthorizedRoute
        element={<List />}
        checkPermisson={{
          addPermission: ADD_PRODUCT,
          updatePermission: UPDATE_PRODUCT,
          deletePermission: DELETE_PRODUCT,
          restorePermission: RESTORE_PRODUCT,
        }}
        permission={DELETE_PRODUCT}
      />
    ),
  },
  {
    path: PRODUCT_INVENTORY_HISTORY,
    element: (
      <AuthorizedRoute
        element={<ProductInventoryHistoryList />}
        permission={VIEW_STOCK_INVENTORY}
      />
    ),
  },
  // { path: PRODUCT_INVENTORY_HISTORY, element: <ProductInventoryHistoryList /> },
];

export default ProductRoutes;
