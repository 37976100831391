import { useEffect, useState } from "react";
import { DOCUMENT_TITLE } from "../../../Routes/Setting/Document";
import { Col, Container, Row, Button, Alert } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { Document } from "../../../Model/Setting/Document";
import { setToast } from "../../../Util/Toast";
import FieldDescription from "../../../Components/FieldDescription";
import Form from "react-bootstrap/Form";
import "grapesjs/dist/css/grapes.min.css";
import "./grapesjs-styles.css";
import GrapesJsEditor from "./GrapesJsEditor";
import ActivityLogs from "../../../Components/ActivityLogs";
import CustomSkeleton from "../../../Components/CustomSkeleton";

function AddUpdate() {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [isShows, setIsShows] = useState<boolean>(false);
  const navigate = useNavigate();
  const [inputTitle, setInputTitle] = useState("");
  const [titleError, setTitleError] = useState<string>("");
  const [typeError, setTypeError] = useState<string>("");
  const [selectedTextError, setSelectedTextError] = useState<string>("");
  const [contentError, setContentError] = useState<string>("");
  const [selectedText, setSelectedText] = useState("");
  const [selectedTexts, setSelectedTexts] = useState<string[]>([]);
  const [formError, setFormError] = useState<string | null>(null);

  const [editorData, setEditorData] = useState<{ html: string; css: string }>({
    html: "",
    css: "",
  });

  const handleInputChange = (event: any) => {
    setInputTitle(event.target.value);
  };
  const getFormData = async (itemId: string) => {
    const item = await Document.$query().find(itemId);
    return item.$attributes;
  };
  const [selectedType, setSelectedType] = useState<string>("");

  const handleTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedType(event.target.value);
  };
  const formFields = [
    {
      id: "inputdocument_name",
      label: "Title",
      description: "Enter a Title.",
    },
    {
      id: "inputContent",
      label: "Content",
      description: "please add any Content",
    },
  ];
  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        try {
          const itemData: any = await getFormData(id);

          setEditorData({
            html: JSON.parse(itemData.content).html,
            css: JSON.parse(itemData.content).css,
          });
          setInputTitle(itemData.title);
          const matchResult =
            itemData.document_template_field[0].match(/\[(\w+)_([\w-]+)\]/);

          setSelectedType(matchResult[2]);
          setSelectedText(matchResult[1]);
        } catch (error: any) {
          setToast("error", error.response?.data.message);
        } finally {
          setTimeout(() => {
            setLoading(false);
          }, 5000);
        }
      } else {
        setLoading(false);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handlesubmit = async (data: any, componentsLength: any) => {
    try {
      setTitleError("");
      setTypeError("");
      setSelectedTextError("");
      setContentError("");

      if (!inputTitle) {
        setTitleError("Title is required");
      }

      if (!selectedType) {
        setTypeError("Type is required");
      }

      if (!selectedText) {
        setSelectedTextError("Text is required");
      }
      if (!data.trim()) {
        setContentError("Content is required");
      }

      if (!componentsLength) {
        setContentError("Content is required");
      }

      if (inputTitle && selectedType && selectedText) {
        const requestData: any = {
          title: inputTitle,
          content: data,
          document_template_field: selectedType &&
            selectedText && [`[${selectedText}_${selectedType}]`],
        };

        if (id) {
          await Document.$query().update(id, requestData);

          setToast("success", `${DOCUMENT_TITLE} Successfully Updated`);
          navigate(-1);
        } else {
          await Document.$query().store(requestData);
          setToast("success", `${DOCUMENT_TITLE} Successfully Added`);
          navigate(-1);
        }
      }
    } catch (error: any) {
      if (error.response?.data?.errors) {
        setFormError(error.response.data.errors);
      } else if (error.response?.data?.message) {
        setFormError(error.response.data.message);
      } else {
        setToast("error", "Failed to save document");
      }
    }
  };

  const handleHelpButtonClick = () => {
    setIsShows(!isShows);
    const formBottom = document.getElementById("form-bottom");
    if (formBottom) {
      formBottom.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };
  const handleSelectButtonClick = () => {
    const Color = "#006400";
    const fontWeight = "bold";

    if (!selectedTexts.some((text) => text.includes(selectedText))) {
      let style = `color: ${Color}; font-weight: ${fontWeight};`;

      if (
        selectedType === "text" ||
        selectedType === "number" ||
        selectedType === "checkbox"
      ) {
        const newContent = `<p style="${style}">${selectedText}</p>`;
        setSelectedTexts((prevSelectedTexts) => [
          ...prevSelectedTexts,
          newContent,
        ]);
      }
    } else {
      setToast("info", "Value already added");
    }
  };

  const handleCopySelectedText = (textToCopy: any) => {
    let formattedText = "";

    if (selectedType === "text") {
      formattedText = `[${textToCopy}_text]`;
    } else if (selectedType === "number") {
      formattedText = `[${textToCopy}_number]`;
    } else if (selectedType === "checkbox") {
      formattedText = `[${textToCopy}_checkbox]`;
    }

    const textarea = document.createElement("textarea");
    textarea.value = formattedText;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);

    setToast("success", "Text copied to clipboard");
  };

  // const DocumentSkeleton = () => {
  //   return <>
  //   </>
  // }

  const DocumentSkeleton = () => {
    return (
      <Container fluid className="p-0">
        <Row>
          <Col md={12}>
            <CustomSkeleton
              height={20}
              width="10%"
              customOuterStyle={{ padding: "0", marginBottom: "10px" }}
            />
            <CustomSkeleton
              height={40}
              width="100%"
              customOuterStyle={{ padding: "0", marginBottom: "10px" }}
            />
          </Col>
          <Col md={6}>
            <CustomSkeleton
              height={20}
              width="20%"
              customOuterStyle={{ padding: "0", marginBottom: "10px" }}
            />
            <CustomSkeleton
              height={40}
              width="100%"
              customOuterStyle={{ padding: "0", marginBottom: "10px" }}
            />
          </Col>
          <Col md={6}>
            <CustomSkeleton
              height={20}
              width="20%"
              customOuterStyle={{ padding: "0", marginBottom: "10px" }}
            />
            <CustomSkeleton
              height={40}
              width="100%"
              customOuterStyle={{ padding: "0", marginBottom: "10px" }}
            />
          </Col>
        </Row>
        <Row>
          <Col className="mt-2">
            <CustomSkeleton
              height={300}
              width="100%"
              style={{ marginBottom: "10px" }}
              customOuterStyle={{ padding: "0", marginTop: "25px" }}
            />
          </Col>
        </Row>
      </Container>
    );
  };

  return (
    <Container>
      {formError && (
        <Alert variant="danger">
          {typeof formError === "string"
            ? formError
            : Object.keys(formError).length > 0 && (
                <>
                  {Object.entries(formError).map(([fieldName, errors]: any) => (
                    <li key={fieldName}>{errors.join(", ")}</li>
                  ))}
                </>
              )}
        </Alert>
      )}
      <Row>
        <Col md={6}>
          <h2>
            {id ? "Edit " : "Add "}
            {DOCUMENT_TITLE}
          </h2>
        </Col>
        <Col md={6}>
          <span style={{ float: "inline-end" }}>
            <Button variant="outline-secondary" onClick={handleHelpButtonClick}>
              Need Help?
            </Button>
          </span>
        </Col>
      </Row>

      {loading ? (
        <DocumentSkeleton />
      ) : (
        <>
          <Row>
            <Col>
              <div className="form-group">
                <label htmlFor="inputTitle">Title*</label>
                <input
                  type="text"
                  className="form-control"
                  id="inputTitle"
                  title="title"
                  placeholder="Enter Title"
                  onChange={handleInputChange}
                  value={inputTitle}
                />
                <div className="error-message text-danger">{titleError}</div>
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md={6}>
              <label htmlFor="typeSelect">Type Select*</label>
              <Form.Select
                id="typeSelect"
                aria-label="Default select example"
                onChange={handleTypeChange}
                value={selectedType}
                isInvalid={typeError.length > 0}
                required={true}
              >
                <option value="">Type Select</option>
                <option value="text">Text</option>
                <option value="number">Number</option>
                <option value="checkbox">Checkbox</option>
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                Type is required
              </Form.Control.Feedback>
            </Col>
            <Col md={6}>
              {selectedType && ( // Conditionally render input field
                <div>
                  <label htmlFor="inputField">
                    {selectedType === "text"
                      ? "Text Input*"
                      : selectedType === "checkbox"
                      ? "Checkbox Input*"
                      : "Number Input*"}
                  </label>
                  <div className="d-flex">
                    <input
                      type="text"
                      className="form-control"
                      id="inputField"
                      placeholder={
                        selectedType === "text"
                          ? "Enter Text"
                          : selectedType === "checkbox"
                          ? "Enter Checkbox*"
                          : "Enter Number"
                      }
                      style={{ float: "left" }}
                      onChange={(e) => setSelectedText(e.target.value)}
                      value={selectedText}
                      required
                    />
                    <button
                      type="button"
                      className="btn btn-primary ms-3"
                      style={{ float: "right" }}
                      onClick={handleSelectButtonClick}
                    >
                      Select
                    </button>
                  </div>
                  {selectedTextError && (
                    <div className="error-message text-danger">
                      {selectedTextError}
                    </div>
                  )}
                </div>
              )}
            </Col>
          </Row>

          <Row>
            <Col md={6} className="mt-3">
              {selectedTexts.map((content, index) => (
                <div
                  key={index}
                  dangerouslySetInnerHTML={{ __html: content }}
                  onClick={() => handleCopySelectedText(selectedText)}
                  style={{ cursor: "pointer", marginBottom: "10px" }}
                />
              ))}
            </Col>
          </Row>

          <Row>
            <Col className="mt-5">
              <label htmlFor="inputContent">Content*</label>
              <GrapesJsEditor
                onSubmitData={(data: any, componentsLength: any) => {
                  handlesubmit(data, componentsLength);
                }}
                initialHtml={editorData.html}
                initialCss={editorData.css}
              />
              <div className="error-message text-danger">{contentError}</div>
            </Col>
          </Row>
        </>
      )}

      <Row>
        <Col>
          <Col>
            <div id="form-bottom">
              <FieldDescription
                title={DOCUMENT_TITLE}
                formFields={formFields}
                isShows={isShows}
              />
            </div>
          </Col>
        </Col>
      </Row>
      <div className="card mt-4" style={{ marginBottom: "30px" }}>
        <div className="card-header d-flex">
          <h5
            style={{ cursor: "pointer", fontSize: "17px" }}
            className="mb-0 me-3 text-primary"
          >
            Activity Logs
          </h5>
        </div>

        <div
          className="card-body"
          style={{
            height: "auto",
            overflow: "auto",
            backgroundColor: "#e4e4e4",
          }}
        >
          <ActivityLogs auditable_type={"DOCUMENT_TEMPLATES"} id={id} />
        </div>
      </div>
    </Container>
  );
}

export default AddUpdate;
