import React from "react";
import { RouteObject } from "react-router-dom";
import List from "../../Pages/Inventory/Material/List";
import AddUpdate from "../../Pages/Inventory/Material/AddUpdate";
import AuthorizedRoute from "../../Util/AuthorizedRoute";
import {
  ADD_MATERIAL,
  DELETE_MATERIAL,
  RESTORE_MATERIAL,
  UPDATE_MATERIAL,
  VIEW_MATERIAL,
} from "../../Util/PermissionList";

export const MATERIAL_TITLE = "Material";
export const MATERIAL_LIST = "/materials";
export const MATERIAL_ADD = "/materials/add";
export const MATERIAL_EDIT = "/materials/:id/edit";
export const MATERIAL_ARCHIVE = "/materials/archive";

const Material: RouteObject[] = [
  {
    path: MATERIAL_LIST,
    element: (
      <AuthorizedRoute
        permission={VIEW_MATERIAL}
        checkPermisson={{
          viewPermission: VIEW_MATERIAL,
          addPermission: ADD_MATERIAL,
          updatePermission: UPDATE_MATERIAL,
          deletePermission: DELETE_MATERIAL,
          restorePermission: RESTORE_MATERIAL,
        }}
        element={<List />}
      />
    ),
  },
  {
    path: MATERIAL_ADD,
    element: (
      <AuthorizedRoute
        element={<AddUpdate />}
        checkPermisson={{
          viewPermission: VIEW_MATERIAL,
          addPermission: ADD_MATERIAL,
          updatePermission: UPDATE_MATERIAL,
          deletePermission: DELETE_MATERIAL,
          restorePermission: RESTORE_MATERIAL,
        }}
        permission={ADD_MATERIAL}
      />
    ),
  },
  {
    path: MATERIAL_EDIT,
    element: (
      <AuthorizedRoute
        element={<AddUpdate />}
        checkPermisson={{
          viewPermission: VIEW_MATERIAL,
          addPermission: ADD_MATERIAL,
          updatePermission: UPDATE_MATERIAL,
          deletePermission: DELETE_MATERIAL,
          restorePermission: RESTORE_MATERIAL,
        }}
        permission={UPDATE_MATERIAL}
      />
    ),
  },
  {
    path: MATERIAL_ARCHIVE,
    element: (
      <AuthorizedRoute
        element={<List />}
        checkPermisson={{
          addPermission: ADD_MATERIAL,
          updatePermission: UPDATE_MATERIAL,
          deletePermission: DELETE_MATERIAL,
          restorePermission: RESTORE_MATERIAL,
        }}
        permission={DELETE_MATERIAL}
      />
    ),
  },
];

export default Material;
