import React, { useState } from "react";
import AddUpdateModel from "./Modal";
import AddUpdateForm from "../../../Components/AddUpdateForm";
import { BUYERS_AND_SUPPLIERS_TITLE } from "../../../Routes/BuyersAndSuppliers/BuyersAndSuppliers";
import { setToast } from "../../../Util/Toast";
import { BuyersAndSuppliers } from "../../../Model/BuyersAndSuppliers/BuyersAndSuppliers";
import { TaxType } from "../../../Model/Setting/TaxType";
import AddUpdateTaxType from "../../Setting/TaxType/AddUpdate";
import { TAX_TYPE_LIST, TAX_TYPE_TITLE } from "../../../Routes/Setting/TaxType";
import { Country } from "../../../Model/Setting/Country";

interface AddUpdateBuyesANDSuppliersProps {
  setModalStaus: any;
  modalStatus: boolean;
}

const AddUpdateBuyesANDSuppliers: React.FC<AddUpdateBuyesANDSuppliersProps> = ({
  setModalStaus,
  modalStatus,
}) => {
  const [showAddUpdateModel, setShowAddUpdateModel] = useState(modalStatus);

  const companyFormFields = [
    {
      id: "inputCompanyType",
      label: "Company Type*",
      type: "select",
      col: 4,
      name: "company_type",
      addNewBtnStatus: false,
      options: [
        { label: "Buyer", value: "buyer" },
        { label: "Supplier", value: "supplier" },
        { label: "Both", value: "both" },
      ],
      placeholder: "Select an Company Type",
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Company Type is required",
        },
      ],
      description:
        "Select the type of business entity: Buyer, Supplier, or Both.",
    },
    {
      id: "inputCompanyName",
      label: "Company Name*",
      type: "text",
      col: 4,
      name: "company_name",
      placeholder: "Enter Your Company Name",
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Company Name is required",
        },
      ],
      description:
        "Enter the name of the company, ensuring accuracy and consistency.",
    },
    {
      id: "inputEmail",
      label: "Email*",
      type: "text",
      col: 4,
      name: "email",
      placeholder: "Enter Your Email",
      validate: [
        {
          rule: (value: any) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value),
          message: "Please enter a valid email address",
        },
        {
          rule: (value: any) => !!value,
          message: "Email is required",
        },
      ],
      description:
        "Provide the email address associated with the company for communication.",
    },
    {
      id: "inputPhoneNumber",
      label: "Phone Number",
      type: "phone_number",
      col: 4,
      name: "phone_number",
      placeholder: "Enter Your Phone Number",
      description: "Enter Your Phone Number.",
    },
    {
      id: "inputTaxNumber",
      label: "Tax Number*",
      type: "text",
      col: 4,
      name: "tax_number",
      placeholder: "Enter Your Tax Number",
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Tax Number is required",
        },
      ],
      description:
        "Include the Value Added Tax (VAT) number for taxation purposes.",
    },
    {
      id: "inputFaxNumber",
      label: "Fax Number",
      type: "text",
      col: 4,
      name: "fax_no",
      placeholder: "Enter Your Fax Number",
      description: "Enter Your Fax Number.",
    },
    {
      id: "inputaddress_line1",
      label: "Address Line 1*",
      type: "text",
      col: 4,
      name: "address_line1",
      placeholder: "Enter Your Address Line 1",
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Address Line 1 is required",
        },
        {
          rule: (value: any) => value && value.length >= 5,
          message: "The address line1 field must be at least 5 characters.",
        },
      ],
      description: "Specify the physical address of the company.",
    },
    {
      id: "inputaddress_line2",
      label: "Address Line 2",
      type: "text",
      col: 4,
      name: "address_line2",
      placeholder: "Enter Your Address Line 2",
    },
    {
      id: "inputPinCode",
      label: "Zip Code*",
      type: "text",
      col: 4,
      name: "zip_code",
      placeholder: "Enter Your Zip Code",
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Zip Code is required",
        },
      ],
      description:
        "Enter the postal code or Zip code associated with the company's location.",
    },
    {
      id: "inputCity",
      label: "City*",
      type: "text",
      col: 4,
      name: "city",
      placeholder: "Enter Your City",
      validate: [
        {
          rule: (value: any) => !!value,
          message: "City is required",
        },
      ],
      description: "Provide the city in which the company is situated.",
    },
    {
      id: "inputState",
      label: "State*",
      type: "text",
      col: 4,
      name: "state",
      placeholder: "Enter Your State",
      validate: [
        {
          rule: (value: any) => !!value,
          message: "State is required",
        },
      ],
      description: "Specify the state or region of the company's location.",
    },
    {
      id: "inputcompany_tcs_setting",
      label: "Company Tax Collection At Source Setting*",
      type: "select",
      addNewBtnStatus: false,
      col: 4,
      name: "company_tcs_setting",
      placeholder: "Select an Company Tax Collection At Source Setting",
      options: [
        { label: "None", value: "none" },
        { label: "Purchase", value: "purchase" },
        { label: "Sales ", value: "sales" },
        { label: "Both", value: "both" },
      ],
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Company Tax Collection At Source Setting is required",
        },
      ],
      description:
        "Select the Tax Collection at Source (TCS) setting for the company.",
    },
    {
      id: "inputcountry_id",
      label: "Country*",
      type: "select",
      col: 4,
      name: "country_id",
      placeholder: "Select an Country",
      searchField: "name",
      model: Country,
      addNewBtnStatus: false,
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Country is required",
        },
      ],
      description: "Choose the country in which the company is located.",
    },
    {
      id: "inputtax_types_id",
      label: "Tax Type*",
      type: "select",
      col: 4,
      name: "tax_type_id",
      placeholder: "Select an TaxType",
      searchField: "title",
      model: TaxType,
      validate: [
        {
          rule: (value: any) => !!value,
          message: "TaxType is required",
        },
      ],
      description:
        "Select the applicable tax type for the company, such as GST, VAT, etc.",
      widget: (
        <AddUpdateTaxType
          title={TAX_TYPE_TITLE}
          listRoute={TAX_TYPE_LIST}
          isNotUpdate={true}
        />
      ),
    },
  ];

  const handleCompanyDetailsClose = () => {
    setShowAddUpdateModel(false);
    setModalStaus((prevState: any) => ({
      ...prevState,
      buyersAndSupplierStatus: false,
    }));
  };
  const [formError, setFormError] = useState<string | null>(null);
  const handleCompanyCustomSubmit = async (formData: any) => {
    // Creating a new object with the updated values
    const updatedData = {
      ...formData,
      tax_type_id: parseInt(formData.tax_type_id.value),
      country_id: parseInt(formData.country_id.value),
      company_type: formData.company_type.value,
      company_tcs_setting: formData.company_tcs_setting.value,
    };

    try {
      await BuyersAndSuppliers.$query().store(updatedData);
      setToast("success", `${BUYERS_AND_SUPPLIERS_TITLE} Successfully Added`);
      setShowAddUpdateModel(false);
      setModalStaus((prevState: any) => ({
        ...prevState,
        buyersAndSupplierStatus: false,
      }));
    } catch (error: any) {
      if (error.response?.data?.errors) {
        setFormError(error.response.data.errors);
      } else if (error.response?.data?.message) {
        setFormError(error.response.data.message);
      } else {
        setToast("error", "An error occurred while processing your request");
      }
    }
  };
  return (
    <AddUpdateModel
      title={`Add ${BUYERS_AND_SUPPLIERS_TITLE}`}
      show={showAddUpdateModel}
      handleClose={() => {
        handleCompanyDetailsClose();
        setFormError(null);
      }}
      size={"xl"}
      formFields={companyFormFields}
      widget={
        <AddUpdateForm
          formFields={companyFormFields}
          model={BuyersAndSuppliers}
          title="Buyers&Suppliers"
          customTitle=" "
          customFormSubmit={handleCompanyCustomSubmit}
          isBackBtnDisable={true}
          dynamicFormError={formError}
          isNotUpdate={true}
          submitButtonLabel="Submit And Fill Next Step"
        />
      }
    />
  );
};

export default AddUpdateBuyesANDSuppliers;
