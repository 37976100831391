import React, { forwardRef, useEffect, useRef } from "react";
import grapesjs from "grapesjs";
import "grapesjs/dist/css/grapes.min.css";
import websitePlugin from "grapesjs-preset-webpage";
import basicBlockPlugin from "grapesjs-blocks-basic";
import formPlugin from "grapesjs-plugin-forms";
import "./grapesjs-styles.css";
import { useNavigate } from "react-router-dom";

interface GrapesJsEditorProps {
  onSubmitData: (data: { html: any; css: any }, componentsLength: any) => void;
  initialHtml?: string;
  initialCss?: string;
}

const GrapesJsEditor: React.ForwardRefRenderFunction<
  any,
  GrapesJsEditorProps
> = ({ onSubmitData, initialHtml = "", initialCss = "" }, ref) => {
  const editorRef = useRef<any>(null);
  const navigate = useNavigate();
  useEffect(() => {
    const initEditor = () => {
      editorRef.current = grapesjs.init({
        container: document.getElementById("gjs") as HTMLDivElement,
        plugins: [websitePlugin, basicBlockPlugin, formPlugin],
      });

      editorRef.current.on("load", () => {
        editorRef.current.setComponents(initialHtml);
        editorRef.current.setStyle(initialCss);
      });
    };

    initEditor();
  }, [initialHtml, initialCss]);
  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <>
      <div id="gjs"></div>
      <div className="d-flex justify-content-between float-end mt-3 mb-3">
        <button
          className="btn btn-secondary me-3"
          type="button"
          onClick={handleGoBack}
        >
          Back
        </button>
        <button
          className="btn btn-primary"
          type="submit"
          onClick={() => {
            const html = editorRef.current.getHtml();
            const cssComposer = editorRef.current.CssComposer;
            const css = cssComposer
              .getAll()
              .map((style: any) => style.toCSS())
              .join("");
            const data: any = JSON.stringify({ html, css });
            const components = editorRef.current.getComponents();
            onSubmitData(data, components.length);
          }}
        >
          Submit
        </button>
      </div>
    </>
  );
};

export default forwardRef(GrapesJsEditor);
