import { Model } from "@tailflow/laravel-orion/lib/model";

export class Document extends Model<{
  title: string;
  organization_id: string;
  content: string;
  color: string;
  value: string;
  created_at: string;
  updated_at: string;
  deleted_at: string;
}> {
  public $resource(): string {
    return "document-templates";
  }
}
