import { TableColumn } from "react-data-table-component";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchData } from "../../../Util/OrionList";
import {
  PRODUCT_ADD,
  PRODUCT_ARCHIVE,
  PRODUCT_EDIT,
  PRODUCT_INVENTORY_HISTORY,
  PRODUCT_LIST,
  PRODUCT_TITLE,
} from "../../../Routes/Inventory/Product";
import { Product } from "../../../Model/Inventory/Product";
import CustomEditButton from "../../../Components/Button/CustomEditButton";
import CustomDataTable from "../../../Components/CustomDataTable";
import { FilterOperator } from "@tailflow/laravel-orion/lib/drivers/default/enums/filterOperator";
import { Col, Row } from "react-bootstrap";
import CustomFilter, { InputValues } from "../../../Components/CustomFilter";
import { setToast } from "../../../Util/Toast";
import ImportData from "../../../Components/ImportData";
import CustomButton from "../../../Components/Button/CustomButton";
import CustomArchiveModal from "../../../Components/CustomArchiveModal";
import CustomArchiveButton from "../../../Components/Button/CustomArchiveButton";
import pluralize from "pluralize";
import CustomRestoreIconButton from "../../../Components/Button/CustomRestoreIconButton";
import CustomRestoreModal from "../../../Components/CustomRestoreModal";
import EditStockModal from "../StockInventory/AddUpdate";
import { VIEW_STOCK_INVENTORY } from "../../../Util/PermissionList";
import { ArrowDown, ArrowUp, ClockHistory } from "react-bootstrap-icons";

export interface ProductItem {
  id: number;
  tax_id: number;
  category_id: number;
  unit_of_measurement: any;
  unit_of_measurement_id: number;
  product_id: string;
  product_name: string;
  type: string;
  current_stock: number;
  change_quantity: number;
  hsn_code: string;
  default_price: number;
  regular_buying_price: number;
  wholesale_buying_price: number;
  regular_selling_price: number;
  mrp: number;
  dealer_price: number;
  distributor_price: number;
  created_at: string;
  updated_at: string;
}

function List({ permissions }: any) {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [data, setData] = useState<ProductItem[]>([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [includeWith] = useState(["unitOfMeasurement"]);
  const [limit, setLimit] = useState(
    Number(searchParams.get("perPage")) === 0
      ? 10
      : Number(searchParams.get("perPage")),
  );
  const [page, setPage] = useState(
    Number(searchParams.get("page")) === 0
      ? 1
      : Number(searchParams.get("page")),
  );
  const navigate = useNavigate();
  const [inputValues, setInputValues] = useState<InputValues>({});
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [showImportPopup, setShowImportPopup] = useState(false);
  const [showArchiveModal, setshowArchiveModal] = useState(false);
  const [ArchiveItemId, setArchiveItemId] = useState<number | null>(null);
  const [sortField, setSortField] = useState<string>("id");
  const [sortOrder, setSortOrder] = useState<string>("desc");
  const [isArchive, setIsArchive] = useState<boolean>(
    location.pathname === PRODUCT_ARCHIVE,
  );
  const [showRestoreModal, setshowRestoreModal] = useState(false);
  const [restoreItemId, setRestoreItemId] = useState<number | null>(null);
  const [initialFetchDone, setInitialFetchDone] = useState(false);
  const [showEditStockModal, setShowEditStockModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<ProductItem | null>(
    null,
  );

  const handleRowEditButtonClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: number,
  ) => {
    e.preventDefault();
    navigate(PRODUCT_EDIT.replace(":id", id.toString()));
  };
  const handleRowArchiveButtonClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: number,
  ) => {
    e.preventDefault();
    setArchiveItemId(id);
    setshowArchiveModal(true);
  };
  const handleRowCreateButtonClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.preventDefault();
    navigate(PRODUCT_ADD);
  };

  const handleArchiveButtonClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.preventDefault();
    navigate(PRODUCT_ARCHIVE);
  };

  const handleRowRestoreButtonClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: number,
  ) => {
    e.preventDefault();
    setRestoreItemId(id);
    setshowRestoreModal(true);
  };

  const handleRowViewButtonClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: number,
  ) => {
    e.preventDefault();
    navigate(PRODUCT_INVENTORY_HISTORY.replace(":id", id.toString()));
  };

  const handleEditStockClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    product: ProductItem,
  ) => {
    e.preventDefault();
    const selectedProduct: any = {
      products_id: { label: product.product_name, value: product.id },
      unit_of_measurement_id: {
        label: product.unit_of_measurement.name,
        value: product.unit_of_measurement.id,
      },
    };
    setSelectedProduct(selectedProduct);
    setShowEditStockModal(true);
  };

  const columns: TableColumn<ProductItem>[] = [
    {
      name: "Id",
      cell: (row) => row.id,
    },
    {
      name: "Name",
      cell: (row) => row.product_name,
    },
    {
      name: "Current Stock",
      style: () => ({
        display: !isArchive ? "flow" : "flex",
      }),
      cell: (row) => (
        <div className="current-stock-cell">
          <div className="stock-and-change">
            <span>{row.current_stock}</span>
            <span
              style={{ marginLeft: "5px", fontSize: "10px", fontWeight: 600 }}
              className={
                row.change_quantity > 0 ? "text-success" : "text-danger"
              }
            >
              {row.change_quantity > 0 ? (
                <ArrowUp color="green" />
              ) : (
                <ArrowDown color="red" />
              )}
              {row.change_quantity}
            </span>
          </div>
          {!isArchive && (
            <span className="edit-button-span">
              <CustomEditButton
                id={row.id}
                onClick={(e: any) => {
                  handleEditStockClick(e, row);
                }}
              />
            </span>
          )}
        </div>
      ),
    },
    {
      name: "Type",
      cell: (row) => {
        const type = row.type.toLowerCase();
        return type.charAt(0).toUpperCase() + type.slice(1);
      },
    },
    {
      name: "Created At",
      cell: (row: any) => row.created_at,
    },
    {
      name: "Updated At",
      cell: (row: any) => row.updated_at,
    },
    {
      name: "Deleted At",
      cell: (row: any) => row.deleted_at,
      omit: !isArchive,
    },
    {
      name: "Actions",
      button: true,
      omit:
        !permissions.update &&
        !permissions.delete &&
        !permissions[VIEW_STOCK_INVENTORY],
      cell: (row) => (
        <div>
          {isArchive ? (
            permissions.restore && (
              <CustomRestoreIconButton
                id={row.id}
                onClick={handleRowRestoreButtonClick}
              />
            )
          ) : (
            <>
              {permissions[VIEW_STOCK_INVENTORY] && (
                <button className="btn btn-xs border-0 p-0">
                  <ClockHistory
                    color={"green"}
                    size={"18px"}
                    onClick={(e: any) => handleRowViewButtonClick(e, row?.id)}
                  />
                </button>
              )}
              {permissions.update && (
                <CustomEditButton
                  id={row.id}
                  onClick={handleRowEditButtonClick}
                />
              )}
              {permissions.delete && (
                <CustomArchiveButton
                  id={row.id}
                  onClick={handleRowArchiveButtonClick}
                />
              )}
            </>
          )}
        </div>
      ),
    },
  ];

  useEffect(() => {
    setSortField("id");
    setSortOrder("desc");
  }, []);

  // @ts-ignore
  const fetchProduct = async () => {
    try {
      await fetchData(
        page,
        limit,
        inputValues,
        setLoading,
        setData,
        setTotalRows,
        navigate,
        new Product(),
        sortField,
        sortOrder,
        isArchive,
        includeWith,
      );
    } catch (error: any) {
      setToast("error", error.response.data.message);
    }
  };

  const handlePerRowsChange = async (rowLimit: any, page: any) => {
    if (limit === rowLimit) {
      setLimit(rowLimit);
      setPage(page);
    } else {
      setLimit(rowLimit);
      setPage(1);
    }
  };

  const handlePageChange = (page: any) => {
    setPage(page);
  };

  useEffect(() => {
    fetchProduct();
  }, [limit, page]); // eslint-disable-line

  useEffect(() => {
    if (initialFetchDone) {
      setPage(1);
      fetchProduct();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isArchive]);

  useEffect(() => {
    setIsArchive(location.pathname === PRODUCT_ARCHIVE);
    setInitialFetchDone(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const handleGoBack = () => {
    navigate(PRODUCT_LIST);
  };

  const filterOptions: any = [
    {
      id: "id",
      value: "id",
      label: "Id",
      fieldType: "text",
      allowedFilter: {
        Equal: FilterOperator.Equal,
        NotEqual: FilterOperator.NotEqual,
        Like: FilterOperator.Like,
        NotLike: FilterOperator.NotLike,
      },
    },
    {
      id: "product_name",
      value: "name",
      label: "Name",
      fieldType: "text",
      allowedFilter: {
        Equal: FilterOperator.Equal,
        NotEqual: FilterOperator.NotEqual,
        Like: FilterOperator.Like,
        NotLike: FilterOperator.NotLike,
      },
    },
    {
      id: "type",
      value: "type",
      label: "Type",
      fieldType: "text",
      allowedFilter: {
        Equal: FilterOperator.Equal,
        NotEqual: FilterOperator.NotEqual,
        Like: FilterOperator.Like,
        NotLike: FilterOperator.NotLike,
      },
    },
    {
      id: "created_at",
      value: "created_at",
      label: "Created Date",
      fieldType: "date",
      allowedFilter: {
        Equal: FilterOperator.Equal,
        NotEqual: FilterOperator.NotEqual,
      },
    },
    {
      id: "updated_at",
      value: "updated_at",
      label: "Updated Date",
      fieldType: "date",
      allowedFilter: {
        Equal: FilterOperator.Equal,
        NotEqual: FilterOperator.NotEqual,
      },
    },
  ];

  const handleImportButtonClick = () => {
    setShowImportPopup(true);
  };

  const handleCloseImportPopup = () => {
    fetchProduct();
    setShowImportPopup(false);
  };

  const handleCloseEditStockPopup = () => {
    fetchProduct();
    setShowEditStockModal(false);
  };

  return (
    <>
      <div className="mb-4">
        <h2>
          {pluralize(PRODUCT_TITLE)} {isArchive ? "Archive" : "List"}
        </h2>
      </div>
      <Row>
        <CustomFilter
          options={filterOptions}
          inputValues={inputValues}
          setInputValues={setInputValues}
          selectedOptions={selectedOptions}
          setSelectedOptions={setSelectedOptions}
          getData={fetchProduct}
          setPage={setPage}
        />
        <Col className="text-xxl-end text-xl-end text-lg-end text-md-start text-sm-start text-xs-start">
          {isArchive ? (
            <CustomButton
              label={`Back`}
              variant="secondary"
              onClick={handleGoBack}
            />
          ) : (
            <>
              {permissions.add && (
                <CustomButton
                  label={`Import`}
                  variant="primary"
                  onClick={handleImportButtonClick}
                  customeClass="ms-2"
                />
              )}
              {permissions.add && (
                <CustomButton
                  label={`Add`}
                  variant="success"
                  onClick={handleRowCreateButtonClick}
                  customeClass="ms-2"
                />
              )}
              {permissions.delete && (
                <CustomButton
                  label={`Archive`}
                  variant="danger"
                  onClick={handleArchiveButtonClick}
                  customeClass="ms-2"
                />
              )}
            </>
          )}
        </Col>
      </Row>
      <CustomDataTable
        columns={columns}
        data={data}
        progressPending={loading}
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        handleRowCreateButtonClick={handleRowCreateButtonClick}
        currentPages={page}
        currentRowsPerPage={limit}
        module={"Product"} // Pass optional fields to CustomDataTable
      />
      <ImportData
        show={showImportPopup}
        handleClose={handleCloseImportPopup}
        model={Product}
        modelName={"products"}
      />
      <CustomArchiveModal
        title={PRODUCT_TITLE}
        model={Product}
        getData={fetchProduct}
        ArchiveItemId={ArchiveItemId}
        setArchiveItemId={setArchiveItemId}
        showArchiveModal={showArchiveModal}
        setshowArchiveModal={setshowArchiveModal}
      />
      <CustomRestoreModal
        title={PRODUCT_TITLE}
        model={Product}
        getData={fetchProduct}
        RestoreItemId={restoreItemId}
        showRestoreModal={showRestoreModal}
        setRestoreItemId={setRestoreItemId}
        setshowRestoreModal={setshowRestoreModal}
      />
      <EditStockModal
        show={showEditStockModal}
        handleClose={handleCloseEditStockPopup}
        product={selectedProduct}
      />
    </>
  );
}

export default List;
