import { RouteObject } from "react-router-dom";
import List from "../../Pages/Project/List";
import ViewManufacturers from "../../Pages/Project/ViewManufacturers";
import AddUpdate from "../../Pages/Project/AddUpdate";
import QuotationFillupForm from "../../Pages/Project/QuotationFillupForm";
import ViewManufacturersQuotationRequests from "../../Pages/Project/ViewManufacturersQuotationRequests";
import ViewProject from "../../Pages/Project/ViewProject";
import AuthorizedRoute from "../../Util/AuthorizedRoute";
import {
  ADD_PROJECT,
  ADD_PROJECT_MANUFACTURES_QUOTATION,
  DELETE_PROJECT,
  DELETE_PROJECT_MANUFACTURES_QUOTATION,
  RESTORE_PROJECT,
  RESTORE_PROJECT_MANUFACTURES_QUOTATION,
  UPDATE_PROJECT,
  UPDATE_PROJECT_MANUFACTURES_QUOTATION,
  VIEW_PROJECT,
  VIEW_PROJECT_MANUFACTURES_QUOTATION,
  VIEW_PROJECT_MANUFACTURES_QUOTATION_DATA,
} from "../../Util/PermissionList";

export const PROJECTS_TITLE = "Projects";
export const PROJECTS_LIST = "/projects";
export const PROJECTS_ADD = "/projects/add";
export const PROJECTS_EDIT = "/projects/:id/edit";
export const PROJECTS_VIEW = "/projects/:id/view";
export const PROJECTS_ARCHIVE = "/projects/archive";
export const PROJECTS_MANUFACTURER_VIEW = "/projects/:id/:sid/view/:mid";
export const PROJECTS_MANUFACTURER_QUOTATION_REQUESTS_VIEW =
  "/projects/:id/view/:mid/quotation/:qid";
export const QUOTATION_FILLUP_FORM = "/project-quotation-public/:token";

const Projects: RouteObject[] = [
  {
    path: PROJECTS_LIST,
    element: (
      <AuthorizedRoute
        permission={VIEW_PROJECT}
        checkPermisson={{
          addPermission: ADD_PROJECT,
          updatePermission: UPDATE_PROJECT,
          deletePermission: DELETE_PROJECT,
          viewPermission: VIEW_PROJECT,
          restorePermission: RESTORE_PROJECT,
        }}
        element={<List />}
      />
    ),
  },
  {
    path: PROJECTS_ADD,
    element: (
      <AuthorizedRoute
        element={<AddUpdate />}
        permission={ADD_PROJECT}
        checkPermisson={{
          addPermission: ADD_PROJECT,
          updatePermission: UPDATE_PROJECT,
          deletePermission: DELETE_PROJECT,
          viewPermission: VIEW_PROJECT,
          restorePermission: RESTORE_PROJECT,
        }}
      />
    ),
  },
  {
    path: PROJECTS_EDIT,
    element: (
      <AuthorizedRoute
        element={<AddUpdate />}
        exception={true}
        checkPermisson={{
          addPermission: ADD_PROJECT,
          updatePermission: UPDATE_PROJECT,
          deletePermission: DELETE_PROJECT,
          viewPermission: VIEW_PROJECT,
          restorePermission: RESTORE_PROJECT,
          VIEW_PROJECT_MANUFACTURES_QUOTATION,
        }}
        permission={UPDATE_PROJECT}
      />
    ),
  },
  {
    path: PROJECTS_VIEW,
    element: (
      <AuthorizedRoute
        element={<ViewProject />}
        exception={true}
        checkPermisson={{
          addPermission: ADD_PROJECT,
          updatePermission: UPDATE_PROJECT,
          deletePermission: DELETE_PROJECT,
          viewPermission: VIEW_PROJECT,
          restorePermission: RESTORE_PROJECT,
          VIEW_PROJECT_MANUFACTURES_QUOTATION_DATA,
        }}
        permission={VIEW_PROJECT}
      />
    ),
  },
  {
    path: PROJECTS_MANUFACTURER_VIEW,
    element: (
      <AuthorizedRoute
        element={<ViewManufacturers />}
        checkPermisson={{
          addPermission: ADD_PROJECT_MANUFACTURES_QUOTATION,
          updatePermission: UPDATE_PROJECT_MANUFACTURES_QUOTATION,
          deletePermission: DELETE_PROJECT_MANUFACTURES_QUOTATION,
          viewPermission: VIEW_PROJECT_MANUFACTURES_QUOTATION,
          restorePermission: RESTORE_PROJECT_MANUFACTURES_QUOTATION,
        }}
        permission={VIEW_PROJECT_MANUFACTURES_QUOTATION}
      />
    ),
  },
  {
    path: PROJECTS_MANUFACTURER_QUOTATION_REQUESTS_VIEW,
    element: (
      <AuthorizedRoute
        element={<ViewManufacturersQuotationRequests />}
        checkPermisson={{
          addPermission: ADD_PROJECT_MANUFACTURES_QUOTATION,
          updatePermission: UPDATE_PROJECT_MANUFACTURES_QUOTATION,
          deletePermission: DELETE_PROJECT_MANUFACTURES_QUOTATION,
          viewPermission: VIEW_PROJECT_MANUFACTURES_QUOTATION,
          restorePermission: RESTORE_PROJECT_MANUFACTURES_QUOTATION,
        }}
        permission={VIEW_PROJECT_MANUFACTURES_QUOTATION}
      />
    ),
  },
  { path: QUOTATION_FILLUP_FORM, element: <QuotationFillupForm /> },
];

export default Projects;
