import "bootstrap/dist/css/bootstrap.min.css";
import { Tax } from "../../../Model/Setting/Tax";
import { Category } from "../../../Model/Inventory/Category";
import { UnitOfMeasurement } from "../../../Model/Setting/UnitOfMeasurement";
import { setToast } from "../../../Util/Toast";
import { useNavigate, useParams } from "react-router-dom";
import AddUpdateForm from "../../../Components/AddUpdateForm";
import { Material } from "../../../Model/Inventory/Material";
import {
  MATERIAL_LIST,
  MATERIAL_TITLE,
} from "../../../Routes/Inventory/Material";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import FieldDescription from "../../../Components/FieldDescription";
import { DynamicFormFields } from "../../../Util/DynamicFormFields";
import { useApi } from "../../../Controller/ApiController";
import Notes from "../../../Components/Notes";
import Attachments from "../../../Components/Attachments";
import { Country } from "../../../Model/Setting/Country";
import ActivityLogs from "../../../Components/ActivityLogs";
import AddUpdateTax from "../../../Pages/Setting/Tax/AddUpdate";
import { TAX_LIST, TAX_TITLE } from "../../../Routes/Setting/Tax";
import {
  CATEGORY_LIST,
  CATEGORY_TITLE,
} from "../../../Routes/Inventory/Category";
import AddUpdateCategory from "../../../Pages/Inventory/Category/AddUpdate";
import AddUpdateUOM from "../../../Pages/Setting/UnitOfMeasurement/AddUpdate";
import {
  UNIT_OF_MEASUREMENT_LIST,
  UNIT_OF_MEASUREMENT_TITLE,
} from "../../../Routes/Setting/UnitOfMeasurement";
import { CapitalizeAndRemoveUnderscore } from "../../../Util/CapitalizeAndRemoveUnderscore";

const AddUpdate = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState<any>({});
  const [formError, setFormError] = useState<string | null>(null);
  const [isShows, setIsShows] = useState<boolean>(false);
  const [dynamicFormFields, setDynamicFormFields] = useState([]);
  const { apiGet } = useApi();
  const [activeTab, setActiveTab] = useState("notes");
  const [customSubmitting, setCustomSubmitting] = useState(false);
  const [taxModalStatus, setTaxModalStatus] = useState(false);
  const [categoryModalStatus, setCategoryModalStatus] = useState(false);
  const [UMOModalStatus, setUMOModalStatus] = useState(false);

  const formFields = [
    {
      id: "inputTaxId",
      label: "Tax*",
      type: "select",
      col: 6,
      name: "tax_id",
      placeholder: "Select Tax",
      searchField: "tax_name",
      model: Tax,
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Tax is required",
        },
      ],
      description:
        "Choose the applicable tax for the material.(Example: 'GST 18%', 'VAT Exempt' etc.)",
      addNewBtnStatus: true,
      onChangeModalStatus: (status: boolean) => setTaxModalStatus(status),
      modalStatus: taxModalStatus,
      widget: (
        <AddUpdateTax
          title={TAX_TITLE}
          listRoute={TAX_LIST}
          isNotUpdate={true}
          onChangeModalStatus={(status: boolean) => setTaxModalStatus(status)}
        />
      ),
    },
    {
      id: "inputCategoryId",
      label: "Category*",
      type: "select",
      col: 6,
      name: "category_id",
      placeholder: "Select Category",
      searchField: "name",
      model: Category,
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Category is required",
        },
      ],
      description:
        "Select the category to which the material belongs. Example: 'Raw Materials', 'Finished Goods', 'Consumables' etc.",
      addNewBtnStatus: true,
      onChangeModalStatus: (status: boolean) => setCategoryModalStatus(status),
      modalStatus: categoryModalStatus,
      widget: (
        <AddUpdateCategory
          title={CATEGORY_TITLE}
          listRoute={CATEGORY_LIST}
          isNotUpdate={true}
          onChangeModalStatus={(status: boolean) =>
            setCategoryModalStatus(status)
          }
        />
      ),
    },
    {
      id: "inputUOMId",
      label: "Unit of Measurement*",
      type: "select",
      col: 6,
      name: "unit_of_measurement_id",
      placeholder: "Select Unit of Measurement",
      searchField: "name",
      model: UnitOfMeasurement,
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Unit of Measurement is required",
        },
      ],
      description:
        "Select the unit of measurement for the material.Example: 'Kilogram (KG)', 'Liter (L)', 'Each (EA)' etc.",
      addNewBtnStatus: true,
      onChangeModalStatus: (status: boolean) => setUMOModalStatus(status),
      modalStatus: UMOModalStatus,
      widget: (
        <AddUpdateUOM
          title={UNIT_OF_MEASUREMENT_TITLE}
          listRoute={UNIT_OF_MEASUREMENT_LIST}
          isNotUpdate={true}
          onChangeModalStatus={(status: boolean) => setUMOModalStatus(status)}
        />
      ),
    },
    {
      id: "inputMaterialID",
      label: "Material ID*",
      type: "text",
      col: 6,
      name: "material_id",
      placeholder: "Enter Material ID",
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Material ID is required",
        },
      ],
      description:
        "Assign a unique identifier or code to distinguish the material.",
    },
    {
      id: "inputMaterialName",
      label: "Material Name*",
      type: "text",
      col: 6,
      name: "material_name",
      placeholder: "Enter Material Name",
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Material Name is required",
        },
      ],
      description: "Enter a descriptive name for the material.",
    },
    {
      id: "inputMaterialType",
      label: "Material Type*",
      type: "select",
      addNewBtnStatus: false,
      col: 6,
      name: "type",
      options: [
        { label: "Buy", value: "buy" },
        { label: "Sell", value: "sell" },
        { label: "Both", value: "both" },
      ],
      placeholder: "Enter Material Type",
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Material Type is required",
        },
      ],
      description:
        "Specify the type of material, such as 'Metal', 'Textile', 'Chemical' etc.",
    },
    {
      id: "inputCurrentStock",
      label: "Current Stock",
      type: "number",
      col: 6,
      name: "current_stock",
      placeholder: "Enter Current Stock",
      description:
        "Indicate the current available stock quantity for the material.",
    },
    // {
    //   id: "inputHsnCode",
    //   label: "HSN Code*",
    //   type: "text",
    //   col: 6,
    //   name: "hsn_code",
    //   placeholder: "Enter HSN Code",
    //   validate: [
    //     {
    //       rule: (value: any) => !!value,
    //       message: "HSN Code is required",
    //     },
    //   ],
    //   description:
    //     "Include the HSN code for proper classification in invoices and taxation.",
    // },
    {
      id: "inputDefaultPrice",
      label: "Default Price*",
      type: "number",
      col: 6,
      name: "default_price",
      placeholder: "Enter default_price",
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Default Price is required",
        },
      ],
      description: "The standard price of the material.",
    },
    {
      id: "inputregular_buying_price",
      label: "Regular Buying Price*",
      type: "number",
      col: 6,
      name: "regular_buying_price",
      placeholder: "Enter Regular Buying Price",
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Regular Buying Price is required",
        },
      ],
      description: "The price at which the material is regularly purchased.",
    },
    {
      id: "inputwholesale_buying_price",
      label: "Wholesale Buying Price*",
      type: "number",
      col: 6,
      name: "wholesale_buying_price",
      placeholder: "Enter Wholesale Buying Price",
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Wholesale Buying Price is required",
        },
      ],
      description: "The price when the material is purchased in bulk.",
    },
    {
      id: "inputregular_selling_price",
      label: "Regular Selling Price*",
      type: "number",
      col: 6,
      name: "regular_selling_price",
      placeholder: "Enter Regular Selling Price",
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Regular Selling Price is required",
        },
      ],
      description: "The regular selling price to customers.",
    },
    {
      id: "inputwholesalesellingprice",
      label: "Wholesale Selling Price*",
      type: "number",
      col: 6,
      name: "wholesale_selling_price",
      placeholder: "Enter Wholesale Selling Price",
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Wholesale Selling Price is required",
        },
      ],
      description:
        "The maximum price at which the product can be sold to the end consumer.",
    },
    {
      id: "inputdealer_price",
      label: "Dealer Price*",
      type: "number",
      col: 6,
      name: "dealer_price",
      placeholder: "Enter Dealer Price",
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Dealer Price is required",
        },
      ],
      description: "The price offered to authorized dealers.",
    },
    {
      id: "inputdistributor_price",
      label: "Distributor Price*",
      type: "number",
      col: 6,
      name: "distributor_price",
      placeholder: "Enter Distributor Price",
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Distributor Price is required",
        },
      ],
      description: "The price offered to authorized distributors.",
    },
    {
      id: "inputDescription",
      label: "Description*",
      type: "text",
      col: 6,
      name: "description",
      placeholder: "Enter description",
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Description is required",
        },
      ],
      description: "The description for material.",
    },
    {
      id: "inputcountry_id",
      label: "Country*",
      type: "select",
      col: 6,
      name: "country_id",
      placeholder: "Select an Country",
      searchField: "name",
      model: Country,
      addNewBtnStatus: false,
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Country is required",
        },
      ],
      description: "Indicate the country in which the material is located.",
    },
  ];

  const handleCustomSubmit = async (formData: any) => {
    setCustomSubmitting(true);
    const dynamicAttributesValues = dynamicFormFields
      .filter((field: any) => field.isDynamic)
      .reduce((result: any[], field: any) => {
        const { name, type } = field;
        if (type === "select") {
          const data: any = formData[name]?.value || formData[name] || "";
          result.push({
            attribute_id: field.attribute_id,
            type,
            data,
          });
        } else if (type === "lookup") {
          let selectedId: any = formData[name];
          result.push({
            attribute_id: field.attribute_id,
            type,
            data: selectedId?.value,
          });
        } else if (type === "multiselect") {
          let selectedIds: any = formData[name] || [];
          selectedIds = selectedIds.map((id: any) => id.value).join(",");

          result.push({
            attribute_id: field.attribute_id,
            type,
            data: selectedIds,
          });
        } else if (type === "checkbox") {
          let isCheckboxChecked;
          if (id) {
            isCheckboxChecked =
              formData[name] && formData[name].includes(field.optionId);
          } else {
            isCheckboxChecked = isCheckboxChecked =
              Number(formData[name]) === field.optionId;
          }

          if (isCheckboxChecked) {
            const selectedIds = Array.isArray(field.optionId)
              ? field.optionId
              : field.optionId
              ? [field.optionId]
              : [];

            const existingEntry = result.find(
              (entry) =>
                entry.attribute_id === field.attribute_id &&
                entry.type === type,
            );

            if (existingEntry) {
              existingEntry.data = existingEntry.data.concat(selectedIds);
            } else {
              result.push({
                attribute_id: field.attribute_id,
                type,
                data: selectedIds,
              });
            }
          }
        } else {
          const data = formData[name] || "";
          result.push({
            attribute_id: field.attribute_id,
            type,
            data,
          });
        }

        return result;
      }, [])
      .map((entry) => ({
        ...entry,
        data: Array.isArray(entry.data) ? entry.data?.join(",") : entry.data,
      }));

    let inputData = Object.assign({}, formData);
    const taxIdNumber = parseInt(inputData.tax_id.value);
    const unitOfMeasurementIdNumber = parseInt(
      inputData.unit_of_measurement_id.value,
    );
    const categoryIdNumber = parseInt(inputData.category_id.value);
    const countryIdNumber = parseInt(inputData?.country_id?.value);
    const filteredAttributesValues = dynamicAttributesValues.filter((item) =>
      item.type === "boolean" ? true : item.data && item.data !== "",
    );

    // Updating the object with the converted values
    inputData.tax_id = taxIdNumber;
    inputData.category_id = categoryIdNumber;
    inputData.country_id = countryIdNumber;
    inputData.unit_of_measurement_id = unitOfMeasurementIdNumber;
    inputData.type = inputData.type.value;
    inputData.attributes = filteredAttributesValues;
    try {
      if (inputData.id) {
        let item = await Material.$query().find(inputData.id);

        item.$attributes = {
          ...item.$attributes,
          ...inputData,
        };

        item = await item.$save();
        setToast("success", `${MATERIAL_TITLE} Successfully Updated`);
      } else {
        await Material.$query().store(inputData);
        setToast("success", `${MATERIAL_TITLE} Successfully Added`);
      }
      navigate(`${MATERIAL_LIST.toLowerCase().replace(" ", "-")}`);
    } catch (error: any) {
      if (error.response?.data?.errors) {
        setFormError(error.response.data.errors);
      } else if (typeof error.response?.data?.message === "object") {
        const dynamicErrors = Object.keys(error.response.data.message)
          .map((key) => error.response.data.message[key])
          .filter((value) => typeof value === "string");

        setFormError(dynamicErrors.join(", "));
      } else if (error.response?.data?.message) {
        setFormError(error.response.data.message);
      } else {
        setToast("error", "An error occurred while processing your request");
      }
    } finally {
      setCustomSubmitting(false);
    }
  };

  const getFormData = async (itemId: string, customeModel?: any) => {
    if (customeModel) {
      const item = await customeModel.$query().find(itemId);
      return item.$attributes;
    } else {
      const item: any = await Material.$query()
        .with(["tax", "category", "unitOfMeasurement", "country"])
        .find(itemId);
      return item.$attributes;
    }
  };

  useEffect(() => {
    DynamicFormFields(formFields, "materials").then((updatedFormFields) => {
      setDynamicFormFields(updatedFormFields);
    });
    const fetchData = async () => {
      if (id) {
        try {
          setLoading(true);
          const itemData = await getFormData(id);
          let data: any = itemData;

          const dynamicAttributeValues = await apiGet(
            `materials/${id}/attributes-values`,
          );

          const getDynamicFormFields = await DynamicFormFields(
            formFields,
            "materials",
          );

          await Promise.all(
            getDynamicFormFields.map(async (dynamicField: any) => {
              const matchingValue = dynamicAttributeValues.data.find(
                (value: any) =>
                  value.attribute_id === dynamicField.attribute_id,
              );
              let value;
              if (matchingValue) {
                if (dynamicField.type === "select") {
                  const selectedOption = dynamicField.options?.find(
                    (option: any) =>
                      option.value === matchingValue.integer_value,
                  );

                  value = {
                    label: selectedOption
                      ? selectedOption.label
                      : matchingValue.integer_value,
                    value: matchingValue.integer_value,
                  };
                } else if (dynamicField.type === "lookup") {
                  const selectedOption = matchingValue.integer_value;
                  const res = await getFormData(
                    selectedOption,
                    dynamicField.model,
                  );
                  value = {
                    label: CapitalizeAndRemoveUnderscore(
                      res[dynamicField.searchField],
                    ),
                    value: matchingValue.integer_value,
                  };
                } else if (dynamicField.type === "multiselect") {
                  // Handle "multiselect" type
                  const selectedOptionIds =
                    matchingValue.text_value
                      ?.split(",")
                      .map((id: string) => id.trim()) || [];

                  const selectedOptions = dynamicField.options?.filter(
                    (option: any) =>
                      selectedOptionIds.includes(String(option.value)),
                  );

                  value = selectedOptions;
                } else {
                  value =
                    matchingValue.text_value !== null
                      ? matchingValue.text_value
                      : matchingValue.integer_value !== null
                      ? matchingValue.integer_value
                      : matchingValue.float_value !== null
                      ? matchingValue.float_value
                      : matchingValue.boolean_value !== null
                      ? matchingValue.boolean_value
                      : matchingValue.json_value !== null
                      ? matchingValue.json_value
                      : matchingValue.date_value
                      ? matchingValue.date_value
                      : matchingValue.datetime_value
                      ? matchingValue.datetime_value
                      : matchingValue.decimal_value
                      ? matchingValue.decimal_value
                      : null;
                }
                data[dynamicField.name] = value;
              }
            }),
          );

          if (data["tax_id"]) {
            data["tax_id"] = {
              label: itemData.tax["tax_name"],
              value: itemData.tax["id"],
            };
          }
          if (data["category_id"]) {
            data["category_id"] = {
              label: itemData.category["name"],
              value: itemData.category["id"],
            };
          }
          if (data["unit_of_measurement_id"]) {
            data["unit_of_measurement_id"] = {
              label: itemData.unit_of_measurement["name"],
              value: itemData.unit_of_measurement["id"],
            };
          }
          if (data["type"]) {
            data["type"] = {
              label: CapitalizeAndRemoveUnderscore(itemData.type),
              value: itemData.type,
            };
          }
          if (data["country_id"]) {
            data["country_id"] = {
              label: itemData.country["name"],
              value: itemData.country["id"],
            };
          }
          setFormData(data);
          setLoading(false);
        } catch (error: any) {
          setToast("error", error.response.data.message);
        } finally {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, taxModalStatus, categoryModalStatus, UMOModalStatus]);

  const handleHelpButtonClick = () => {
    setIsShows(!isShows);
    const formBottom = document.getElementById("form-bottom");
    if (formBottom) {
      formBottom.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };
  return (
    <>
      <div>
        <span style={{ float: "inline-end" }}>
          <Button variant="outline-secondary" onClick={handleHelpButtonClick}>
            Need Help?
          </Button>
        </span>
        <AddUpdateForm
          formFields={dynamicFormFields}
          model={Material}
          title={MATERIAL_TITLE}
          customFormSubmit={handleCustomSubmit}
          dynamicFormData={formData}
          listRoute={MATERIAL_LIST}
          dynamicFormError={formError}
          customSubmitting={customSubmitting}
          loadingStatus={loading}
        />
      </div>

      {id && (
        <div className="card mt-4" style={{ marginBottom: "30px" }}>
          <div className="card-header d-flex">
            <h5
              style={{ cursor: "pointer", fontSize: "17px" }}
              className={`mb-0 me-3 ${
                activeTab === "notes" ? "text-primary" : ""
              }`}
              onClick={() => setActiveTab("notes")}
            >
              Notes
            </h5>
            <h5
              style={{ cursor: "pointer", fontSize: "17px" }}
              className={`mb-0 me-3 ${
                activeTab === "activityLogs" ? "text-primary" : ""
              }`}
              onClick={() => setActiveTab("activityLogs")}
            >
              Activity Logs
            </h5>
            <h5
              style={{ cursor: "pointer", fontSize: "17px" }}
              className={`mb-0 me-3 ${
                activeTab === "attachments" ? "text-primary" : ""
              }`}
              onClick={() => setActiveTab("attachments")}
            >
              Attachments
            </h5>
          </div>

          <div
            className="card-body"
            style={{
              height: "auto",
              overflow: "auto",
              backgroundColor: "#e4e4e4",
            }}
          >
            {activeTab === "notes" && <Notes id={id} model={Material} />}
            {activeTab === "activityLogs" && (
              <ActivityLogs auditable_type={"MATERIALS"} id={id} />
            )}
            {activeTab === "attachments" && (
              <Attachments
                fieldType="material_id"
                moduleType="material"
                id={id}
              ></Attachments>
            )}
          </div>
        </div>
      )}

      <div id="form-bottom">
        <FieldDescription
          title={MATERIAL_TITLE}
          formFields={formFields}
          isShows={isShows}
        />
      </div>
    </>
  );
};

export default AddUpdate;
